<template>
    <div class="pagebox">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校详情 -->
        <div class="college_detail_head" :style="{'background':'url('+(GetCollegeHeadImage(DetailData.CollegeImageList))+') no-repeat top center / cover'}">
            <div class="head_back">
                <div class="head_auto">
                    <div class="head_box">
                        <div class="head_top">
                            <div class="logo_box">
                                <div class="logo">
                                    <div class="img" :style="{'background':'url('+DetailData.CollegeLogoFileUrl+') no-repeat center center / cover'}"></div>
                                </div>
                            </div>
                            <div class="txt_box">
                                <div class="cname">
                                    <span class="name">{{DetailData.CollegeName}}</span>
                                    <span class="type">{{DetailData.ProvinceName}}<span v-if="DetailData.ProvinceName&&DetailData.CityName">/</span>{{DetailData.CityName}}</span>
                                    <span class="type">{{DetailData.CategoryName}}<span v-if="DetailData.CategoryName&&DetailData.NatureName">/</span>{{DetailData.NatureName}}<span v-if="(DetailData.CategoryName||DetailData.NatureName)&&DetailData.ChargeDept">/</span>{{DetailData.ChargeDept}}</span>
                                </div>
                                <div class="dress">
                                    <span><i class="el-icon-location-outline"></i>{{DetailData.Address}}</span>
                                    <span><i class="el-icon-phone-outline"></i>{{DetailData.Tel}}</span>
                                </div>
                                <div class="tip_box">
                                    <span v-for="t1 in GetTagsArr(DetailData.StrengthTags)" :key="(t1+'')">{{t1}}</span>
                                    <span v-for="t2 in GetTagsArr(DetailData.FeatureTags)" :key="(t2+'')">{{t2}}</span>
                                </div>
                            </div>
                            <div class="btn_box">
                                <div class="btn" @click.prevent="ToWebsite(DetailData.WebSite)">进入官网</div>
                                <!-- <div class="btn" @click.prevent="OpenReport(DetailData.JobReportFileUrl)">就业质量报告预览</div> -->
                                <div class="btn" @click.prevent="ToWebVR(DetailData.VRUrl)">VR 校园</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 左侧菜单 -->
        <div class="college_left_nav" id="college_left_nav" :class="left_nav_class">
            <div class="open" @click.prevent="LeftNavDownUp">
                <span v-if="LeftNavStatus"><i class="el-icon-d-arrow-right up"></i>收起导航</span>
                <span v-else><i class="el-icon-d-arrow-right down"></i>展开导航</span>
            </div>
            <el-scrollbar wrap-class="scrollbar-wrapper" class="left_nav_box" id="left_nav_box">
                <div class="nav" :class="(TabActiveId=='collegeinfo'?'on':'')" @click.prevent="LeftNavBtn('collegeinfo')">院校介绍</div>

                <div class="nav" :class="(TabActiveId=='department'?'on':'')" @click.prevent="LeftNavBtn('department')">院系设置</div>
                
                <div class="nav" :class="(TabActiveId=='majorStrength'?'on':'')" @click.prevent="LeftNavBtn('majorStrength')">专业实力</div>

                <div class="nav" :class="(TabActiveId=='assessment'?'on':'')" @click.prevent="LeftNavBtn('assessment')">学科评估</div>
                <div class="nav" :class="(TabActiveId=='constitution'?'on':'')" @click.prevent="LeftNavBtn('constitution')">招生章程</div>
                <!-- <div class="nav" :class="(TabActiveId=='Tab5'?'on':'')" @click.prevent="LeftNavBtn('Tab5')">选考要求</div> -->
                <div class="nav" :class="(TabActiveId=='plan'?'on':'')" @click.prevent="LeftNavBtn('plan')">招生计划</div>
                <div class="nav" :class="(TabActiveId=='enrolldata'?'on':'')" @click.prevent="LeftNavBtn('enrolldata')">录取<br/>分数线</div>
                <div class="nav" :class="(TabActiveId=='cooperation'?'on':'')" @click.prevent="LeftNavBtn('cooperation')">中外合作</div>
                <div class="nav" :class="(TabActiveId=='policy'?'on':'')" @click.prevent="LeftNavBtn('policy')">转专业<br/>政策</div>
                <div class="nav" :class="(TabActiveId=='jobReport'?'on':'')" @click.prevent="LeftNavBtn('jobReport')">就业报告</div>

            </el-scrollbar>
        </div>
        <!-- 内容 -->
        <div class="college_detail_box" id="college_detail_box">
            <div class="college_detail_con">
                <div class="tab_box">
                    <el-tabs v-model="TabActiveId" @tab-click="handleTabs" type="card">
                        <el-tab-pane label="院校概况" name="collegeinfo">
                            <div class="tab_con">
                                <!--院校介绍-->
                                <div class="con_box">
                                    <div class="title"><span class="on">院校介绍</span></div>
                                    <div class="content">
                                        <div class="info" v-html="DetailData.CollegeDesc?DetailData.CollegeDesc.replace(/(\n)/g, '<br/>'):''"></div>
                                        <div class="more">
                                            <div class="back"></div>
                                            <div class="btn">
                                                <div class="b" @click.prevent="DetailInfoLayerOpen()">
                                                    查看更多内容<i class="el-icon-d-arrow-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="detail_layer_back" v-if="DetailInfoLayerShow" :class="DetailLayerClass">
                                            <div class="detail_layer_box">
                                                <div class="layer_con" :class="DetailLayerConClass">
                                                    <div class="bt"><span class="tt">院校介绍</span><i class="el-icon-close" @click.prevent="DetailInfoLayerClose()"></i></div>
                                                    <el-scrollbar wrap-class="scrollbar-wrapper" class="con"><div v-html="DetailData.CollegeDesc?DetailData.CollegeDesc.replace(/(\n)/g, '<br/>'):''"></div></el-scrollbar>
                                                    <div class="back">
                                                        <div class="btn" @click.prevent="DetailInfoLayerClose()">返 回</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--院校风光-->
                                <div class="con_box">
                                    <div class="title"><span class="on">院校风光</span></div>
                                    <div class="content">
                                        <div class="imgbox">
                                            <div class="img" v-for="item in DetailData.CollegeImageList" :key="item.ImageUrl" :style="{'background':'url('+item.ImageUrl+') no-repeat center center / cover'}"></div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <!--院校排名、综合评级-->
                                <div class="con_box">
                                    <div class="title"><span :class="(OrderNoBox==1?'on':'no')" @click.prevent="OrderNoBtn(1)">院校排名</span><span :class="(OrderNoBox==2?'on':'no')" @click.prevent="OrderNoBtn(2)">综合评级</span></div>
                                    <div class="content">
                                        <div class="orderbox" v-if="OrderNoBox==1">
                                            <div class="type">
                                                <div class="t">软科</div>
                                                <div class="t">ESI</div>
                                                <div class="t">QS</div>
                                                <div class="t">USNews</div>
                                                <div class="t">泰晤士</div>
                                                <div class="t">校友会</div>
                                                <div class="t">武书连</div>
                                            </div>
                                            <div class="order">
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='软科').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='ESI排名').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='QS').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='USNews').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='泰晤士').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='校友会').RankNum:''}}</div>
                                                <div class="o">{{DetailData.CollegeRankList?DetailData.CollegeRankList.find(f=>f.RankCategoryName=='武书连').RankNum:''}}</div>
                                            </div>
                                        </div>
                                        <div class="orderbox" v-if="OrderNoBox==2">
                                            <div class="type">
                                                <div class="t">综合实力</div>
                                                <div class="t">专业实力</div>
                                                <div class="t">城市</div>
                                                <div class="t">就业</div>
                                                <div class="t">地域</div>
                                                <div class="t">属性</div>
                                                <div class="t">总分</div>
                                            </div>
                                            <div class="order">
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='综合实力').Score:''}}</div>
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='专业实力').Score:''}}</div>
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='城市').Score:''}}</div>
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='就业').Score:''}}</div>
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='地域').Score:''}}</div>
                                                <div class="o">{{DetailData.CollegeIndicateList?DetailData.CollegeIndicateList.find(f=>f.IndicatorName=='属性').Score:''}}</div>
                                                <div class="o">{{GetTotalScore(DetailData.CollegeIndicateList)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--实验室与研究机构、师资力量-->
                                <div class="con_box">
                                    <div class="title"><span :class="(AbilityBox==1?'on':'no')" @click.prevent="AbilityBoxBtn(1)">实验室与研究机构</span><span :class="(AbilityBox==2?'on':'no')" @click.prevent="AbilityBoxBtn(2)">师资力量</span></div>
                                    <div class="content">
                                        <div class="info nonum" v-if="AbilityBox==1">
                                            <p v-for="item in DetailData.CollegeLoboratoryList" v-show="item.LaboratoryList" :key="item.CategoryName"><b>{{item.CategoryName}}：</b><span v-for="(item,index) in item.LaboratoryList" :key="index"><span v-if="index!=0">,</span>{{item}}</span></p>
                                        </div>
                                        <div class="info nonum" v-if="AbilityBox==2">
                                            <p>{{DetailData.TeachersDescription	}}</p>
                                        </div>
                                    </div>
                                </div>
                                <!--男女比例和深造情况-->
                                <div class="con_box">
                                    <div class="title"><span class="on">男女比例和深造情况</span></div>
                                    <div class="content">
                                        <div class="proportionbox">
                                            <div class="pbox">
                                                <div class="tit">男女比例</div>
                                                <div class="num">
                                                    <span class="item"><span>男生：</span><span class="n1"><span class="n2" :style="{'width':DetailData.PercentOfMale+'%'}"></span></span><span style="margin-left:10px;">{{DetailData.PercentOfMale}}%</span><div class="clear"></div></span>
                                                    <span class="item"><span>女生：</span><span class="n1"><span class="n2" :style="{'width':DetailData.PercentOfFemale+'%'}"></span></span><span style="margin-left:10px;">{{DetailData.PercentOfFemale}}%</span></span>
                                                </div>
                                            </div>
                                            <div class="pbox">
                                                <div class="tit">读研比例</div>
                                                <div class="num" style="margin-top:44px;">
                                                    <span class="item"><span class="n1"><span class="n2" :style="{'width':DetailData.PercentOfPostgraduate+'%'}"></span></span><span style="margin-left:10px;">{{DetailData.PercentOfPostgraduate}}%</span></span>
                                                </div>
                                            </div>
                                            <div class="pbox">
                                                <div class="tit">海外发展比例</div>
                                                <div class="num" style="margin-top:44px;">
                                                    <span class="item"><span class="n1"><span class="n2" :style="{'width':DetailData.PercentOfGoAbroad+'%'}"></span></span><span style="margin-left:10px;">{{DetailData.PercentOfGoAbroad}}%</span></span>
                                                </div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                                <!--历史沿革-->
                                <div class="con_box">
                                    <div class="title"><span class="on">历史沿革</span></div>
                                    <div class="content">
                                        <div class="timeline">
                                            <div class="lbox" style="max-height:300px;">
                                                <div class="line" v-for="(item,index) in DetailData.CollegeHistoryList" :key="index">
                                                    <div class="ra"></div>
                                                    <div class="time">{{item.DatePoint}}</div>
                                                    <div class="text">{{item.Description}}</div>
                                                </div>
                                            </div>
                                            <div class="detail_layer_back" v-if="DetailTimeLayerShow" :class="DetailLayerClass">
                                                <div class="detail_layer_box">
                                                    <div class="layer_con" :class="DetailLayerConClass">
                                                        <div class="bt"><span class="tt">历史沿革</span><i class="el-icon-close" @click.prevent="DetailTimeLayerClose()"></i></div>
                                                        <el-scrollbar wrap-class="scrollbar-wrapper" style="height: calc(100% - 140px);">
                                                            <div class="lbox">
                                                                <div class="line" v-for="(item,index) in DetailData.CollegeHistoryList" :key="index">
                                                                    <div class="ra"></div>
                                                                    <div class="time">{{item.DatePoint}}</div>
                                                                    <div class="text">{{item.Description}}</div>
                                                                </div>
                                                            </div>
                                                        </el-scrollbar>
                                                        <div class="back">
                                                            <div class="btn" @click.prevent="DetailTimeLayerClose()">返 回</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="more">
                                                <div class="back"></div>
                                                <div class="btn">
                                                    <div class="b" @click.prevent="DetailTimeLayerOpen()">
                                                        查看更多内容<i class="el-icon-d-arrow-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clear" style="height:30px;"></div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="院系设置" name="department">
                            <CollegeSet></CollegeSet>
                        </el-tab-pane>
                        <el-tab-pane label="专业实力" name="majorStrength">
                            <MajorStrength></MajorStrength>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="开设专业" name="Tab2">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="subjecthead" cellspacing="0">
                                    <tr>
                                        <td>类别</td>
                                        <td>数量</td>
                                        <td>专业</td>
                                    </tr>
                                </table>
                                <table class="subjecttable" cellspacing="0">
                                    <tr>
                                        <td>优势专业</td>
                                        <td>{{DetailData.CollegeMajor?DetailData.CollegeMajor.SuperiorityMajorList.length:''}}</td>
                                        <td>
                                            <div v-if="DetailData.CollegeMajor">
                                                <span class="zy" v-for="(item,index) in DetailData.CollegeMajor.SuperiorityMajorList" :key="index">{{item.Name}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>特色专业</td>
                                        <td>{{DetailData.CollegeMajor?DetailData.CollegeMajor.FeatureMajorList.length:''}}</td>
                                        <td>
                                            <div v-if="DetailData.CollegeMajor">
                                                <span class="zy" v-for="(item,index) in DetailData.CollegeMajor.FeatureMajorList" :key="index">{{item.Name}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(item,index) in (DetailData.CollegeMajor?DetailData.CollegeMajor.CategoryMajorList:[])" :key="index">
                                        <td>{{item.MajorCategory}}</td>
                                        <td>{{item.MajorList.length}}</td>
                                        <td>
                                            <span class="zy" v-for="(majior,num) in item.MajorList" :key="num">{{majior.Name}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane> -->
                        <el-tab-pane label="学科评估" name="assessment">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="subjecthead" cellspacing="0" v-if="DetailData.CollegeLearnSubjectEvaluateList!=0">
                                    <tr>
                                        <td>评级</td>
                                        <td>数量</td>
                                        <td>专业</td>
                                    </tr>
                                </table>
                                <table class="subjecttable" cellspacing="0" v-if="DetailData.CollegeLearnSubjectEvaluateList!=0">
                                    <tr v-for="(item,index) in DetailData.CollegeLearnSubjectEvaluateList" :key="index">
                                        <td>{{item.EvaluateLevel}}</td>
                                        <td>{{item.LearnSubjectList.length}}</td>
                                        <td>
                                            <span class="zy" v-for="(major,num) in item.LearnSubjectList" :key="num">{{major}}</span>
                                        </td>
                                    </tr>
                                </table>
                                <div v-if="DetailData.CollegeLearnSubjectEvaluateList==0" class="nodata">
                                    <i class="iconfont">&#xe67c;</i>
                                    <span>未查询到数据！</span>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="招生章程" name="constitution">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <div class="recruitgeneral">
                                    <div class="recruitlist">
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(cons.Id)" v-for="cons in CollegeConstitutionData" :key="cons.Id">
                                            <div class="title">{{cons.Title}}</div>
                                            <div class="num">年份：{{cons.Year||'-'}}</div>
                                            <div class="time">发布时间：{{cons.ReleaseTime?cons.ReleaseTime.substring(0,10):'-'}}</div>
                                        </div>
                                    </div>
                                    <div class="pages" @click.prevent="pagesbtn()">
                                        <i class="iconfont" v-if="CollegeConstitutionData.length==0&&pageIndex==1">&#xe67c;</i>
                                        <span>{{pagetxt}}</span>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="选考要求" name="Tab5" v-if="false">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="examhead" cellspacing="0">
                                    <tr>
                                        <td>专业名称</td>
                                        <td>选考要求</td>
                                        <td>包含专业</td>
                                    </tr>
                                </table>
                                <table class="examtable" cellspacing="0">
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="招生计划" name="plan">
                            <AdmissionPlan></AdmissionPlan>
                        </el-tab-pane>
                        <el-tab-pane label="录取分数线" name="enrolldata">
                            <AdmissionCollegeAndMajor></AdmissionCollegeAndMajor>
                        </el-tab-pane>
                        
                        <!-- <el-tab-pane label="学科实力" name="Tab9">
                            <SubjectStrength :DetailData="DetailData"></SubjectStrength>
                        </el-tab-pane> -->

                        <el-tab-pane label="中外合作" name="cooperation">
                            <cooperation></cooperation>
                        </el-tab-pane>

                        <el-tab-pane label="转专业政策" name="policy">
                            <policy></policy>
                        </el-tab-pane>
                        
                        <el-tab-pane label="就业报告" name="jobReport">
                            <JobsReport></JobsReport>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import AdmissionPlan from "@/views/query/college/admissionplan.vue";
import AdmissionCollegeAndMajor from "@/views/query/college/admissioncollegeandmajor.vue";
import MajorStrength from "@/views/query/college/majorstrength.vue";
import SubjectStrength from "@/views/query/college/subjectstrength.vue";
import CollegeSet from "@/views/query/college/collegeset.vue";
import JobsReport from "@/views/query/college/jobsreport.vue";
import Policy from '@/views/query/college/policy.vue'
import Cooperation from '@/views/query/college/cooperation.vue'

export default{
    components:{
        PHeader,
        PFooter,
        AdmissionPlan,
        AdmissionCollegeAndMajor,
        MajorStrength,
        SubjectStrength,
        CollegeSet,
        JobsReport,
        Policy,
        Cooperation
    },
    data(){
        return{
            TabActiveId:'collegeinfo',
            LeftNavStatus:true,
            left_nav_class:'',
            DetailLayerClass:'',
            DetailLayerConClass:'',
            DetailInfoLayerShow:false,
            DetailTimeLayerShow:false,
            OrderNoBox:1,
            AbilityBox:1,
            DetailData:{},
            CollegeConstitutionData:[],
            pageIndex:1,
            pageSize:10,
            pagetxt:'未查询到数据',
            HasNext:false,
        }
    },
    mounted(){
        this.GetCollegeInfo();// 获取详情
        this.CalcLeftNav();// 计算左侧菜单位置及高度
        this.GetCollegeConstitutionList();// 获取院校招生简章列表

        this.selectTab() // 选中指定tab
        
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            let cln=document.getElementById('college_left_nav');
            if(cln){
                if (ddeht > 360) {
                    cln.style.display='block';
                }
                else {
                    cln.style.display='none';
                }
            }
        }, true);
    },
    methods:{
        handleTabs(t, e) { // 切换tab同步地址
            let q = this.$route.query
            q.tab = this.TabActiveId

            let url = this.$router.resolve({
                path: '/query/collegedetail',
                query: q
            })
            history.replaceState(null, null, url.href)
            
        },
        selectTab() {
            let tab = this.$route.query.tab
            if (tab) {
                this.TabActiveId = tab
            } else {
                this.TabActiveId = 'collegeinfo'
            }
        },
        GetTotalScore(data){
            let s = 0;
            data.forEach(obj=>{
                s+=obj.Score;
            });
            return s;
        },
        // 获取详情
        GetCollegeInfo(){
            API.Query.GetCollegeInfo({Id:this.$route.query.id}).then(res => {
                if(res.Code===0){
                    this.DetailData = res.Data;
                }
            });
        },
        // 获取院校招生简章列表
        GetCollegeConstitutionList(){
            API.Query.GetCollegeNewsList(this.pageIndex,this.pageSize,{CollegeId:this.$route.query.id, CategoryCode: 'ZSZC'}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.CollegeConstitutionData.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到数据！';
                }
            });
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetCollegeConstitutionList();
                }
            }else{
                if(this.CollegeConstitutionData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        // 头部背景图片
        GetCollegeHeadImage(arr){
            if(arr!=[]&&arr&&arr.length>0){
                return arr[0].ImageUrl;
            }
        },
        // 院校标签格式转换
        GetTagsArr(item){
            if(item){
                if(item.indexOf(',')!=-1){
                    return item.split(',');
                }
                else{
                    return [item];
                }
            }
        },
        // 官网跳转
        ToWebsite(url){
            if(url){
                window.open(url);
            }
        },
        // 打开质量报告
        OpenReport(url) {
            if (url) {
                window.open(PDFPreviewUrl+'?file='+encodeURIComponent(url));
            } else {
                this.$messageTips('warning', '暂无相关报告！')
            }
        },
        //VR跳转
        ToWebVR(url){
            if(url){
                window.open(url);
            }else {
                this.$messageTips('warning', '暂无VR地址！')
            }
        },
        // 计算左侧菜单位置及高度
        CalcLeftNav(){
            let pagewidth = document.body.clientWidth || document.documentElement.clientWidth;
            let conwidth = document.getElementById("college_detail_box").clientWidth;
            let num = ((pagewidth-conwidth) / 2);
            if(num>78){
                document.getElementById('college_left_nav').style.left= (num - 68)+'px';
            }
            else{
                document.getElementById('college_left_nav').style.left='0px';
            }
            let pageheight = window.innerHeight;
            if(pageheight>388){
                document.getElementById("left_nav_box").style.height = '465px';
            }
            else{
                document.getElementById("left_nav_box").style.height = (pageheight-125)+'px';
            }
        },
        // 左侧菜单点击
        LeftNavBtn(id){
            this.TabActiveId = id;
            window.scrollTo(0, 362);

            let q = this.$route.query
            q.tab = this.TabActiveId

            let url = this.$router.resolve({
                path: '/query/collegedetail',
                query: q
            })

            history.replaceState(null, null, url.href)
        },
        // 左侧菜单收起/打开
        LeftNavDownUp(){
            if(this.LeftNavStatus){
                this.left_nav_class='animate_up';
                this.LeftNavStatus=false;
            }
            else{
                this.left_nav_class='animate_down';
                this.LeftNavStatus=true;
            }
        },
        // 打开更多内容 院校介绍
        DetailInfoLayerOpen(){
            this.DetailInfoLayerShow=true;
            this.DetailLayerClass='layerbackshow';
            this.DetailLayerConClass='animate_open';
        },
        // 关闭更多内容 院校介绍
        DetailInfoLayerClose(){
            this.DetailLayerClass='layerbackhide';
            this.DetailLayerConClass='animate_close';
            let st=setTimeout(()=>{
                this.DetailInfoLayerShow=false;
                clearTimeout(st);
            },300);
        },
        // 打开更多内容 历史沿革
        DetailTimeLayerOpen(){
            this.DetailTimeLayerShow=true;
            this.DetailLayerClass='layerbackshow';
            this.DetailLayerConClass='animate_open';
        },
        // 关闭更多内容 历史沿革
        DetailTimeLayerClose(){
            this.DetailLayerClass='layerbackhide';
            this.DetailLayerConClass='animate_close';
            let st=setTimeout(()=>{
                this.DetailTimeLayerShow=false;
                clearTimeout(st);
            },300);
        },
        // 院校排名、综合评级 切换
        OrderNoBtn(val){
            this.OrderNoBox=val;
        },
        // 科研实力 切换
        AbilityBoxBtn(val){
            this.AbilityBox=val;
        },
        // 招生章程详情
        ToArticleDetail(id){
            window.open(this.$router.resolve({ path: "/query/articledetail?id="+id }).href, "_blank");
        }
    }
}
</script>
<style lang="less" scoped>
.college_detail_head{
    width: 100%;
    height: 240px;
    .head_back{
        width: 100%;
        height: 100%;
        background-color: rgba(36,53,71,0.9);
        .head_auto{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            height: 100%;
            .head_box{
                margin: 0px 20px;
                height: 100%;
                .head_top{
                    width: 100%;
                    height: 180px;
                    overflow: hidden;
                    .logo_box{
                        width: 140px;
                        height: 140px;
                        background-color: rgba(255,255,255,0.2);
                        border-radius: 70px;
                        margin-top: 20px;
                        float: left;
                        .logo{
                            width: 120px;
                            height: 120px;
                            margin: 10px;
                            overflow: hidden;
                            background-color: rgba(255,255,255,1);
                            border-radius: 60px;
                            .img{
                                width: 100px;
                                height: 100px;
                                border-radius: 50px;
                                margin: 10px;
                            }
                        }
                    }
                    .txt_box{
                        width: calc(100% - 320px);
                        float: left;
                        margin-left: 30px;
                        .cname{
                            color: #fff;
                            margin-top: 30px;
                            .name{
                                font-size: 30px;
                                letter-spacing: 2px;
                            }
                            .type{
                                font-size: 16px;
                                margin-left: 20px;
                            }
                        }
                        .dress{
                            width: 100%;
                            line-height: 20px;
                            margin-top: 10px;
                            color: #b1b1b1;
                            font-size: 13px;
                            span{
                                display: inline-block;
                                margin-right: 20px;
                                i{
                                    margin-right: 4px;
                                }
                            }
                        }
                        .tip_box{
                            margin-top: 20px;
                            height: 26px;
                            overflow: hidden;
                            span{
                                background-color: rgba(255, 255, 255,0.18);
                                display: inline-block;
                                padding: 1px 10px;
                                color: #d3d3d3;
                                font-size: 12px;
                                border-radius: 10px;
                                margin-right: 5px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                    .btn_box{
                        width: 150px;
                        float: right;
                        margin-top: 20px;
                        .btn{
                            padding: 6px 0px;
                            color: #c0c0c0;
                            font-size: 14px;
                            border-radius: 3px;
                            border: 1px solid #7c7c7c;
                            margin: auto;
                            text-align: center;
                            letter-spacing: 1px;
                            margin-bottom: 15px;
                            overflow: hidden;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                        }
                        .btn:hover{
                            cursor: pointer;
                            color: #d3d1d1;
                            border: 1px solid #bbbaba;
                            background-color: rgba(0,0,0,0.5);
                        }
                    }
                }
            }
        }
    }
}
.college_left_nav{
    width: 78px;
    background-color: #fff;
    border-radius: 3px;
    position: fixed;
    top: 80px;
    overflow: hidden;
    height: auto;
    display: none;
    user-select: none;
    z-index: 20;
    .open{
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #eee;
        font-size: 12px;
        background-color: rgba(var(--themecolor),0.85);
        i{
            color: #e6e6e6;
            font-size: 12px;
            margin-right: 3px;
        }
        i.up{
            transform:rotate(-90deg);
            -webkit-transform:rotate(-90deg);
        }
        i.down{
            transform:rotate(90deg);
            -webkit-transform:rotate(90deg);
        }
    }
    .open:hover{
        background-color: rgba(var(--themecolor),1);
    }
    .left_nav_box{
        border: 1px solid #c7c7c7;
        border-top: none;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .nav{
            color: #666;
            font-size: 14px;
            text-align: center;
            line-height: 18px;
            border-bottom: 1px solid #ddd;
            padding: 12px 10px;
            cursor: pointer;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }
        .nav.on{
            color: #444;
            background-color: rgba(var(--themecolor),0.2);
        }
        .nav:hover{
            color: #444;
            background-color: rgba(var(--themecolor),0.3);
        }
        .nav:last-child{
            border-bottom:none;
        }
    }
}
.college_left_nav.animate_up{
    height: 30px;
}
.college_left_nav.animate_down{
    height: auto;
}
.college_detail_box{
    max-width: 1420px;
    margin: 0px auto 50px;
    user-select: none;
    .college_detail_con{
        margin: 0px 20px;
        .tab_box{
            overflow: hidden;
            margin-top: -39px;
            .tab_con{
                min-height: 500px;
                border: 1px solid #d1d1d1;
                border-top: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #fff;
                .con_box{
                    margin: 0 20px;
                    padding-top: 30px;
                    .title{
                        width: 100%;
                        height: 30px;
                        border-bottom: 1px solid #ddd;
                        border-bottom-right-radius: 5px;
                        span{
                            color: #666;
                            font-size: 14px;
                            height: 29px;
                            display: inline-block;
                            line-height: 29px;
                            padding: 0px 20px;
                            border-top: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            background-color: #fff;
                            border-bottom: 1px solid #ddd;
                        }
                        span.no:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.9);
                            background-color: rgba(var(--themecolor),0.1);
                        }
                        span.on{
                            color: #444;
                            border-bottom: 1px solid #fff;
                        }
                        span:first-child{
                            border-top-left-radius: 5px;
                            border-left: 1px solid #ddd;
                        }
                        span:last-child{
                            border-top-right-radius: 5px;
                        }
                    }
                    .content{
                        border: 1px solid #ddd;
                        border-top: none;
                        border-top-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        min-height: 100px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 30px;
                        .info{
                            color: #666;
                            font-size: 14px;
                            text-indent: 28px;
                            line-height: 24px;
                            margin: 20px 20px 10px;
                            max-height: 186px;
                            overflow: hidden;
                        }
                        .info.nonum{
                            max-height: none;
                        }
                        .imgbox{
                            margin: 20px 10px 15px;
                            .img{
                                width: calc(100% / 4 - 20px);
                                margin: 0px 10px;
                                height: 180px;
                                display: inline-block;
                                border-radius: 5px;
                            }
                        }
                        .orderbox{
                            margin: 20px 20px 15px;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            .type{
                                width: 100%;
                                height: 50px;
                                background-color: rgba(var(--themecolor),0.15);
                                .t{
                                    width: calc(100% / 7 - 1px);
                                    display: inline-block;
                                    text-align: center;
                                    line-height: 50px;
                                    color: #333;
                                    font-size: 16px;
                                    border-left: 1px solid #fff;
                                }
                                .t:first-child{
                                    border-left: none;
                                }
                            }
                            .order{
                                width: 100%;
                                height: 80px;
                                .o{
                                    width: calc(100% / 7 - 1px);
                                    display: inline-block;
                                    text-align: center;
                                    line-height: 80px;
                                    color: rgba(var(--themecolor),0.8);
                                    font-size: 20px;
                                    border-left: 1px solid #e7e7e7;
                                }
                                .o:first-child{
                                    border-left: none;
                                }
                            }
                        }
                        .proportionbox{
                            margin: 20px 10px 15px;
                            .pbox{
                                width: calc(100% / 3 - 22px);
                                margin: 0px 10px;
                                height: 160px;
                                float: left;
                                border-radius: 5px;
                                border: 1px solid #e4e4e4;
                                .tit{
                                    width: 100%;
                                    height: 50px;
                                    background-color: rgba(var(--themecolor),0.15);
                                    color: #555;
                                    font-size: 16px;
                                    line-height: 50px;
                                    text-align: center;
                                }
                                .num{
                                    margin: 30px 20px 0;
                                    color: #444;
                                    font-size: 11px;
                                    display: block;
                                    height: 20px;
                                    line-height: 20px;
                                    .item{
                                        width: 100%;
                                        display: block;
                                        margin-bottom: 10px;
                                        span{
                                            float: left;
                                        }
                                    }
                                    .n1{
                                        width: calc(100% - 100px);
                                        display: inline-block;
                                        height: 20px;
                                        background-color: #e4e4e4;
                                        border-radius: 10px;
                                        position: relative;
                                        overflow: hidden;
                                        .n2{
                                            position: absolute;
                                            height: 100%;
                                            background-color: rgba(var(--themecolor),0.75);
                                            border-radius: 10px;
                                        }
                                    }
                                }
                            }
                        }
                        .timeline{
                            margin: 20px 20px 15px;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            position: relative;
                            overflow: hidden;
                            .lbox{
                                margin: 20px;
                                border-left: 1px solid #ddd;
                                .line{
                                    margin: 0px 20px 30px;
                                    position: relative;
                                    .ra{
                                        width: 9px;
                                        height: 9px;
                                        background-color: rgba(var(--themecolor),1);
                                        border-radius: 10px;
                                        position: absolute;
                                        left: -25px;
                                        top: 5px;
                                        z-index: 1;
                                    }
                                    .time{
                                        color: #888;
                                        font-size: 14px;
                                    }
                                    .text{
                                        color: #555;
                                        font-size: 14px;
                                        margin: 10px 20px 0px;
                                    }
                                }
                            }
                        }
                        .more{
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            height: 86px;
                            width: 100%;
                            .back{
                                width: 100%;
                                height: 56px;
                                background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.01));
                            }
                            .btn{
                                width: 100%;
                                height: 30px;
                                background-color: #fff;
                                .b{
                                    color: #666;
                                    font-size: 14px;
                                    margin: 0px auto 0;
                                    width: 150px;
                                    background-color: #fff;
                                    text-align: center;
                                    cursor: pointer;
                                    i{
                                        margin-left: 5px;
                                        transform:rotate(90deg);
                                        -webkit-transform:rotate(90deg);
                                    }
                                }
                                .b:hover{
                                    color: rgba(var(--themecolor),0.75);
                                }
                            }
                        }
                    }
                }
                .subjecthead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                            width: 220px;
                        }
                        td:nth-child(2){
                            width: 100px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 322px);
                        }
                    }
                }
                .subjecttable{
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    display: block;
                    tr{
                        width: 100%;
                        display: inline-block;
                        border-bottom: 1px solid #ddd;
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            font-size: 14px;
                            padding: 30px 0px;
                            text-align: center;
                            .zy{
                                padding: 2px 10px;
                                color: #6e6c6c;
                                font-size: 14px;
                                margin: 5px 10px;
                                display: inline-block;
                            }
                        }
                        td:first-child{
                            border-left:none;
                            width: 200px;
                            padding: 0 10px;
                            color: rgba(var(--themecolor),1);
                            font-size: 20px;
                        }
                        td:last-child{
                            text-align: left;
                        }
                        td:nth-child(2){
                            width: 100px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 322px);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                    tr:last-child{
                        border-bottom: none;
                    }
                }
                .examhead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                            width: 260px;
                        }
                        td:nth-child(2){
                            width: 260px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 522px);
                        }
                    }
                }
                .examtable{
                    table-layout: fixed !important;
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    width: calc(100% - 40px);
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            font-size: 14px;
                            line-height: 22px;
                            padding: 20px 10px;
                        }
                        td:first-child{
                            border-left:none;
                            width: 240px;
                        }
                        td:nth-child(2){
                            width: 240px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 522px);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .recruitgeneral{
                    margin: 30px 20px 30px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                    .recruitlist{
                        margin: 20px 20px 0;
                        .recruitbox{
                            width: 100%;
                            height: 60px;
                            line-height: 60px;
                            margin-bottom: 20px;
                            background-color: #f5f5f5;
                            border-radius: 5px;
                            color: #555;
                            font-size: 14px;
                            .title{
                                text-indent: 20px;
                                transition: all 0.3s;
                                -webkit-transition: all 0.3s;
                                width: calc(100% - 380px);
                                height: 100%;
                                float: left;
                            }
                            .num{
                                display: inline-block;
                                width: 180px;
                            }
                            .time{
                                display: inline-block;
                                width: 200px;
                            }
                        }
                        .recruitbox:hover{
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                            background-color: #f1f0f0;
                        }
                    }
                    .pages{
                        width: 100%;
                        min-height: 50px;
                        line-height: 50px;
                        color: #666;
                        font-size: 16px;
                        text-align: center;
                        letter-spacing: 1px;
                        margin-bottom: 10px;
                        user-select: none;
                        .iconfont{
                            font-size: 50px;
                            display: block;
                            margin-top: 40px;
                            color: #9b9b9b;
                        }
                    }
                    .pages:hover{
                        color: rgb(var(--themecolor));
                        cursor: pointer;
                    }
                }
                .nodata{
                    color:#666;
                    font-size:14px;
                    text-align:center;
                    i{
                        display: block;
                        margin: auto;
                        padding-top: 50px;
                        font-size: 60px;
                        color:#acabab;
                    }
                }
            }
        }
    }
}
.detail_layer_back{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    background-color: rgba(0,0,0,0.4);
    .detail_layer_box{
        max-width: 1420px;
        margin: 30px auto;
        height: calc(100% - 60px);
        .layer_con{
            margin: 0px 20px;
            background-color: #fff;
            height: calc(100% - 40px);
            position: relative;
            top: 20px;
            border-radius: 5px;
            overflow: hidden;
            .bt{
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #ddd;
                background-color: #fff;
                position: relative;
                .tt{
                    color: #666;
                    font-size: 16px;
                    line-height: 40px;
                    margin-left: 20px;
                }
                i{
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                    display: inline-block;
                    width: 40px;
                    height: 100%;
                    line-height: 40px;
                    color: #888;
                    font-size: 20px;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                i:hover{
                    color: rgba(var(--themecolor),1);
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }
            }
            .con{
                color: #666;
                font-size: 14px;
                text-indent: 28px;
                line-height: 28px;
                margin: 20px 30px 20px;
                height: calc(100% - 140px);
            }
            .back{
                width: 100%;
                height: 60px;
                border-top: 1px solid #ddd;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                .btn{
                    width: 150px;
                    height: 40px;
                    margin: 10px auto 0;
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                    font-size: 14px;
                    line-height: 40px;
                    border-radius: 5px;
                    text-align: center;
                }
                .btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .layer_con.animate_open{
            animation: animate_open 0.3s ease 0s 1;
            -webkit-animation: animate_open 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_open {
                from {
                    top: -200px;
                }
                to {
                    top: 20px;
                }
            }
            @keyframes animate_open {
                from {
                    top: -200px;
                }
                to {
                    top: 20px;
                }
            }
        }
        .layer_con.animate_close{
            animation: animate_close 0.3s ease 0s 1;
            -webkit-animation: animate_close 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_close {
                from {
                    top: 20px;
                }
                to {
                    top: -200px;
                }
            }
            @keyframes animate_close {
                from {
                    top: 20px;
                }
                to {
                    top: -200px;
                }
            }
        }
    }
}
.detail_layer_back.layerbackshow {
    animation: layerbackshow 0.3s ease 0s 1;
    -webkit-animation: layerbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.detail_layer_back.layerbackhide {
    animation: layerbackhide 0.3s ease 0s 1;
    -webkit-animation: layerbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
</style>
<style lang="less">
.Class_RichTextContent img,.Class_RichTextContent video{
    width: auto;
    max-width: 100%;
    display: block;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__header{
    margin: 0;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__nav{
    z-index: 0;
    margin-left: 1px;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__item{
    color: #eee;
    background-color: rgba(var(--themecolor),0.75);
    padding: 0 30px;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__item:hover{
    color: #fff;
    background-color: rgba(var(--themecolor),0.55);
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header{
    border-bottom: none;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__nav{
    border: none;
    overflow: hidden;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
    border-left: none;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item{
    border-left: 1px solid rgba(var(--themecolor),0.55);
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
    color: #666;
    background-color: #fff;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-focus {
  box-shadow: none;
  border-radius: 0;
}
.college_left_nav .left_nav_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.detail_layer_back .detail_layer_box .layer_con .el-scrollbar__wrap{
    overflow-x: hidden;
}
.college_detail_box .el-range-editor.is-active, .college_detail_box .el-range-editor.is-active:hover, .college_detail_box .el-select .el-input.is-focus .el-input__inner{
    border-color: rgba(var(--themecolor),0.55);
}
.college_detail_box .plan_select .el-input__inner{
    height: 34px;
    line-height: 34px;
}
.college_detail_box .plan_select .el-select .el-input__inner:focus{
    border-color: #ddd;
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
.college_detail_box .plan_select .el-input__icon{
    line-height: 34px;
}
</style>