import { render, staticRenderFns } from "./majorstrength.vue?vue&type=template&id=e627a870&scoped=true&"
import script from "./majorstrength.vue?vue&type=script&lang=js&"
export * from "./majorstrength.vue?vue&type=script&lang=js&"
import style0 from "./majorstrength.vue?vue&type=style&index=0&id=e627a870&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e627a870",
  null
  
)

export default component.exports