<template>
    <div class="tab_con">
        <div class="con_box">
            <div class="title">
                <span :class="(CollegeSetActive==1?'on':'no')" @click.prevent="CollegeSetCheck(1)">院系设置</span>
                <span v-if="mainData['2'] && mainData['2'].length>0" :class="(CollegeSetActive==2?'on':'no')" @click.prevent="CollegeSetCheck(2)">重点班</span>
            </div>
            <div class="content" v-if="CollegeSetActive!=-1">
                <div class="conbox" v-for="(item, index) in mainData[String(CollegeSetActive)]" :key="item.DepartmentId">
                    <div class="tbox"><span class="name" @click="openDetail(item, 1)">{{item.DepartmentName}}</span></div>
                    <div class="list">
                        <span class="t1">包含专业：</span>
                        <span class="t2" v-for="(sIt,sI) in item.DepartmentMajorList" :key="sIt.DepartmentMajorId">
                            <span class="name" @click="openDetail(sIt, 2)">{{ sIt.DepartmentMajorName }}</span>
                            <span v-if="sI!==item.DepartmentMajorList.length-1">、</span>
                            <span v-if="sIt.ChildDepartmentMajorList && sIt.ChildDepartmentMajorList.length>0">（</span>
                            <span v-for="(ssIt, ssI) in sIt.ChildDepartmentMajorList" :key="ssIt.DepartmentMajorId">
                                <span class="name" @click="openDetail(ssIt, 2)">{{ ssIt.DepartmentMajorName }}</span>
                                <span v-if="ssI!==sIt.ChildDepartmentMajorList.length-1">、</span>
                            </span>
                            <span v-if="sIt.ChildDepartmentMajorList && sIt.ChildDepartmentMajorList.length>0">）</span>
                        </span>
                        <!-- <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span> -->
                    </div>
                </div>
                <!-- <div class="conbox">
                    <div class="tbox">电子信息与电气工程学院</div>
                    <div class="list">
                        <span class="t1">包含专业：</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                    </div>
                </div>
                <div class="conbox">
                    <div class="tbox">电子信息与电气工程学院</div>
                    <div class="list">
                        <span class="t1">包含专业：</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                        <span class="t2">电子信息类（IEEE试点班）</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>

        <el-dialog :title="content1.DepartmentName" :visible.sync="open1" top="10vh" width="80%">
            <div slot="title">
                <div class="title">{{ content1.DepartmentName }}</div>
                <div>
                    <span class="t">包含专业：</span>
                    <span v-for="(it, i) in content1.DepartmentMajorList" :key="i">
                        <span class="name" @click="openDetail(it, 2)">{{ it.DepartmentMajorName }}</span>
                        <span v-if="i!==content1.DepartmentMajorList.length-1">、</span>

                        <span v-if="it.ChildDepartmentMajorList.length>0">（</span>
                        <span v-for="(cIt, cI) in it.ChildDepartmentMajorList">
                            <span class="name" @click="openDetail(it, 2)">{{ cIt.DepartmentMajorName }}</span>
                            <span v-if="i!==it.ChildDepartmentMajorList.length-1">、</span>
                        </span>
                        <span v-if="it.ChildDepartmentMajorList.length>0">）</span>
                    </span>
                </div>
            </div>
            <div v-if="content1.DepartmentContent" class="box" style="height: 60vh; overflow: auto;" v-html="content1.DepartmentContent"></div>
            <div v-else class="no-data">暂无数据</div>
        </el-dialog>

        <el-dialog :title="content2.DepartmentMajorName" :visible.sync="open2" top="10vh" width="80%">
            <div v-if="content2.DepartmentMajorContent" class="box" style="height: 60vh; overflow: auto;" v-html="content2.DepartmentMajorContent"></div>
            <div v-else class="no-data">暂无数据</div>
        </el-dialog>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default {
    data() {
        return{
            CollegeSetActive:1,
            UserData:null,
            HaveVip:false,

            open1: false,
            open2: false,

            mainData: {},

            content1: {},
            content2: {}
        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        this.getData()

                        // this.provinceObj = JSON.parse(temp);
                        // this.AMDParameters.ProvinceId = this.provinceObj.ProvinceId;
                        // this.AMDParameters.CollegeId = this.$route.query.id;
                        // this.GetAdmissionCollege(this.provinceObj.ProvinceId);// 获取院校录取数据
                        // this.GetAdmissionMajorQueryParameters(this.provinceObj.ProvinceId);// 获取专业录取数据查询参数
                    }
                }
            }
        }
    },
    methods:{
        openDetail(it, type) {
            if (type===1) {
                API.Query.GetCollegeDepartmentDetail({Id: it.DepartmentId}).then(res => {
                    console.log(res, 'DepartmentId')

                    this.content1 = res.Data

                    this.open1 = true
                })
            }
            if (type===2) {
                API.Query.GetCollegeDepartmentMajorDetail({Id: it.DepartmentMajorId}).then(res => {
                    console.log(res, 'DepartmentId2')

                    this.content2 = res.Data

                    this.open2 = true
                })
            }
        },
        // 学科类别选中
        CollegeSetCheck(val){
            this.CollegeSetActive=val;

            console.log(this.CollegeSetActive, 'CollegeSetActive')
        },

        async getData() {
            let res = await API.Query.GetCollegeDepartmentList({
                CollegeId: this.$route.query.id
            })

            this.mainData = res.Data
        }
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .con_box{
        margin: 0 20px;
        padding-top: 20px;
        .title{
            height: 30px;
            background-color: #fff;
            position: relative;
            z-index: 1;
            border-bottom: 1px solid #ddd;
            border-bottom-right-radius: 5px;
            margin-bottom: 0px;
            span{
                color: #666;
                font-size: 14px;
                height: 30px;
                display: inline-block;
                line-height: 29px;
                padding: 0px 20px;
                border-top: 1px solid #ddd;
                border-right: 1px solid #ddd;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
            }
            span.no:hover{
                cursor: pointer;
                color: rgba(var(--themecolor),0.9);
            }
            span.on{
                color: #444;
                border-bottom: 1px solid #fff;
            }
            span:first-child{
                border-top-left-radius: 5px;
                border-left: 1px solid #ddd;
            }
            span:last-child{
                border-top-right-radius: 5px;
            }
        }
        .content{
            border: 1px solid #ddd;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            min-height: 400px;
            margin-top: -1px;
            .conbox{
                margin: 20px;
                border: 1px solid #ddd;
                border-radius: 5px;
                .tbox{
                    color: #333;
                    font-size: 20px;
                    margin: 10px 20px 0;
                }
                .list{
                    margin: 8px 20px 10px;
                    line-height: 24px;
                    .t1{
                        color: #555;
                    }
                    .t2{
                        color: #666;
                    }
                }
                
            }
        }
    }
    .title {
        margin-bottom: 10px;
        color: #555;
        font-size: 20px;
    }
    .t {
        color: #888;
        font-size: 15px;
    }
    .name {
        color: rgba(var(--themecolor), 0.8);
        cursor: pointer;
    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
    ::v-deep .el-dialog__body {
        padding: 10px 0px;
    }
    .box {
        padding: 10px;
    }
    .no-data {
        height: 200px;
        text-align: center;
        font-size: 18px;
        color: #999;
    }
}
</style>