<template>
    <div class="tab_con">
        <div class="con_box">
            <div class="title">
                <span :class="(SubjectItemActive==1?'on':'no')" @click.prevent="SubjectItemCheck(1)">国家级重点学科</span>
                <span :class="(SubjectItemActive==2?'on':'no')" @click.prevent="SubjectItemCheck(2)">研究生招生</span>
                <span :class="(SubjectItemActive==3?'on':'no')" @click.prevent="SubjectItemCheck(3)">双一流学科</span>
                <span :class="(SubjectItemActive==4?'on':'no')" @click.prevent="SubjectItemCheck(4)">博士硕士授权学科</span>
                <span :class="(SubjectItemActive==-1?'on':'no')" @click.prevent="SubjectItemCheck(-1)">学科评估</span>
            </div>
            <div class="content" v-if="SubjectItemActive!=-1">
                <div class="tablebox">
                    <div class="tbox"><span class="t"></span><span class="n">国家重点一级学科</span><div class="clear"></div></div>
                    <div class="list">
                        <div class="tr">
                            <div class="td">哲学</div>
                            <div class="td">理论经济学</div>
                            <div class="td">法学</div>
                            <div class="td">政治学</div>
                            <div class="td">社会学</div>
                            <div class="td">中国语言文学</div>
                            <div class="td">历史学</div>
                            <div class="td">数学</div>
                            <div class="td">物理学</div>
                            <div class="td">化学</div>
                            <div class="td">地理学</div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
                <div class="tablebox">
                    <div class="tbox"><span class="t"></span><span class="n">国家重点二级学科</span><div class="clear"></div></div>
                    <div class="list">
                        <div class="tr">
                            <div class="td">英语语言文学</div>
                            <div class="td">印度语言文学</div>
                            <div class="td">固体地球物理学</div>
                            <div class="td">通信与信息系统</div>
                            <div class="td">核技术及应用</div>
                            <div class="td">中国语言文学</div>
                            <div class="td">环境科学</div>
                            <div class="td">图书馆学</div>
                            <div class="td">天体物理</div>
                            <div class="td">国民经济学</div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
                <div class="tablebox">
                    <div class="tbox"><span class="t"></span><span class="n">国家重点培育学科</span><div class="clear"></div></div>
                    <div class="list">
                        <div class="tr">
                            <div class="td">亚非语言文学</div>
                            <div class="td">矿物学、岩石学、矿床学</div>
                            <div class="td">儿少卫生与妇幼保健学</div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subjectpg" v-if="SubjectItemActive==-1">
                <table class="subjecthead" cellspacing="0">
                    <tr>
                        <td>评级</td>
                        <td>数量</td>
                        <td>专业</td>
                    </tr>
                </table>
                <table class="subjecttable" cellspacing="0">
                    <tr v-for="(item,index) in DetailData.CollegeLearnSubjectEvaluateList" :key="index">
                        <td>{{item.EvaluateLevel}}</td>
                        <td>{{item.LearnSubjectList.length}}</td>
                        <td>
                            <span class="zy" v-for="(major,num) in item.LearnSubjectList" :key="num">{{major}}</span>
                        </td>
                    </tr>
                </table>
                <div v-if="DetailData.CollegeLearnSubjectEvaluateList==0" class="nodata">
                    <i class="iconfont">&#xe67c;</i>
                    <span>未查询到数据！</span>
                </div>
            </div>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default {
    data() {
        return{
            SubjectItemActive:1,
            UserData:null,
            HaveVip:false
        }
    },
    props: {
        DetailData: {
			type: Object,
			default: []
		},
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        // this.provinceObj = JSON.parse(temp);
                        // this.AMDParameters.ProvinceId = this.provinceObj.ProvinceId;
                        // this.AMDParameters.CollegeId = this.$route.query.id;
                        // this.GetAdmissionCollege(this.provinceObj.ProvinceId);// 获取院校录取数据
                        // this.GetAdmissionMajorQueryParameters(this.provinceObj.ProvinceId);// 获取专业录取数据查询参数
                    }
                }
            }
        }
    },
    methods:{
        // 学科类别选中
        SubjectItemCheck(val){
            this.SubjectItemActive=val;
        }
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .con_box{
        margin: 0 20px;
        padding-top: 20px;
        .title{
            height: 30px;
            background-color: #fff;
            position: relative;
            z-index: 1;
            span{
                color: #666;
                font-size: 14px;
                height: 29px;
                display: inline-block;
                line-height: 29px;
                padding: 0px 20px;
                border-top: 1px solid #ddd;
                border-right: 1px solid #ddd;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
            }
            span.no:hover{
                cursor: pointer;
                color: rgba(var(--themecolor),0.9);
                background-color: rgba(var(--themecolor),0.1);
            }
            span.on{
                color: #444;
                border-bottom: 1px solid #fff;
            }
            span:first-child{
                border-top-left-radius: 5px;
                border-left: 1px solid #ddd;
            }
            span:last-child{
                border-top-right-radius: 5px;
            }
        }
        .content{
            border: 1px solid #ddd;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            min-height: 400px;
            .tablebox{
                margin: 20px;
                .tbox{
                    margin-top: 30px;
                    .t{
                        display: block;
                        width: 4px;
                        height: 20px;
                        background-color: rgba(var(--themecolor),0.8);
                        float: left;
                    }
                    .n{
                        color: #555;
                        font-size: 14px;
                        display: block;
                        height: 20px;
                        line-height: 20px;
                        margin-left: 5px;
                        float: left;
                    }
                }
                .list{
                    margin-top: 10px;
                    .tr{
                        border-top: 1px solid #ddd;
                        border-left: 1px solid #ddd;
                        .td{
                            width: calc((100% / 6) - 21px);
                            height: 70px;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            border-right: 1px solid #ddd;
                            float: left;
                            border-bottom: 1px solid #ddd;
                            color: #666;
                            font-size: 14px;
                            display: flex;
                            padding: 0 10px;
                            overflow: hidden;
                        }
                    }
                    .tr:last-child{
                        border-bottom:none;
                    }
                }
                .leftnav{
                    width: 200px;
                    float: left;
                    .nav{
                        padding: 20px 0;
                        color: #666;
                        font-size: 16px;
                        border-bottom: 1px solid #ddd;
                        text-align: center;
                        border-right: 1px solid #ddd;
                    }
                    .nav:last-child{
                        border-bottom: none;
                    }
                }
                .rightbox{
                    width: calc(100% - 200px);
                    float: right;
                    height: 400px;
                }
            }
        }
        .subjectpg{
            border: 1px solid #ddd;
            margin-bottom: 30px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .subjecthead{
                margin: 30px 20px 0px;
                border-radius: 5px;
                background-color: #fff;
                display: block;
                table-layout: fixed;
                border: 1px solid #ddd;
                border-bottom-left-radius:0;
                border-bottom-right-radius:0;
                overflow: hidden;
                background-color: rgba(var(--themecolor),0.1);
                tr{
                    display:block;
                    td{
                        color: #555;
                        font-size: 16px;
                        padding: 15px 0px;
                        text-align: center;
                        border-left: 1px solid #ddd;
                        display: inline-block;
                    }
                    td:nth-child(1){
                        border-left: none;
                        width: 220px;
                    }
                    td:nth-child(2){
                        width: 100px;
                    }
                    td:nth-child(3){
                        width: calc(100% - 322px);
                    }
                }
            }
            .subjecttable{
                margin: 0px 20px 30px;
                border-radius: 5px;
                background-color: #fff;
                table-layout: fixed;
                border: 1px solid #ddd;
                border-top: none;
                border-top-left-radius:0;
                border-top-right-radius:0;
                overflow: hidden;
                display: block;
                tr{
                    width: 100%;
                    display: inline-block;
                    border-bottom: 1px solid #ddd;
                    td{
                        border-left: 1px solid #ddd;
                        color: #777;
                        font-size: 14px;
                        padding: 30px 0px;
                        text-align: center;
                        .zy{
                            padding: 2px 10px;
                            color: #6e6c6c;
                            font-size: 14px;
                            margin: 5px 10px;
                            display: inline-block;
                        }
                    }
                    td:first-child{
                        border-left:none;
                        width: 200px;
                        padding: 0 10px;
                        color: rgba(var(--themecolor),1);
                        font-size: 20px;
                    }
                    td:last-child{
                        text-align: left;
                    }
                    td:nth-child(2){
                        width: 100px;
                    }
                    td:nth-child(3){
                        width: calc(100% - 322px);
                    }
                }
                tr:last-child td{
                    border-bottom:none;
                }
                tr:nth-child(2n+1){
                    background-color: #fdfdfd;
                }
                tr:last-child{
                    border-bottom: none;
                }
            }
            .nodata{
                color:#666;
                font-size:14px;
                text-align:center;
                i{
                    display: block;
                    margin: auto;
                    padding-top: 50px;
                    font-size: 60px;
                    color:#acabab;
                }
            }
        }
    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>