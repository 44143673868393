<template>
    <div class="tab_con">
        <div class="con_box">
            <div class="searchbox">
                <div class="sxbox" @click.prevent="OpenMajorSearch()">专业特色筛选<i class="el-icon-d-arrow-right"></i></div>

                <div class="match-box" @click="selectMatch"><input class="check-box" type="checkbox" v-model="completeMatch" /><span class="text">完全匹配</span></div>
                
                <div class="input">
                    <input type="text" placeholder="请输入专业名称" v-model="MajorName" maxlength="50" />
                    <el-button class="btn" @click="sureSearch"><i class="el-icon-search"></i>搜 索</el-button>
                    <!-- <div class="btn"><i class="el-icon-search" @click="sureSearch"></i>搜 索</div> -->
                    <div class="clear"></div>
                </div>
            </div>
            <div class="searchlist" v-if="MajorSearchCheckDataCopy.length!=0">
                <div class="ttxt">已选条件 ：</div>
                <div class="t_data">
                    <div class="data">
                        <span class="d" v-for="(item,index) in MajorSearchCheckDataCopy" :key="index">{{item.Name}}<i class="el-icon-circle-close" @click.prevent="MajorSearchRemove(item)"></i></span>
                        <div class="close" @click.prevent="MajorSearchRemove('')"><i class="el-icon-close"></i></div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="orderbox">
                <!-- <div class="orderbox">
                    <div class="oitem" :class="OrderCheckActive==1?'on':''" @click.prevent="OrderCheck(1)">专业排名<i class="iconfont">&#xe697;</i></div>
                    <div class="oitem" :class="OrderCheckActive==2?'on':''" @click.prevent="OrderCheck(2)">专业级别<i class="iconfont">&#xe697;</i></div>
                    <div class="oitem" :class="OrderCheckActive==3?'on':''" @click.prevent="OrderCheck(3)">专业特色<i class="iconfont">&#xe697;</i></div>
                    <div class="clear"></div>
                </div> -->
                <div class="order">
                    <div class="item">
                        <span class="name">专业排名</span>
                        <span class="icon">
                            <i class="el-icon-caret-top" :class="OrderCheckActive==11?'on':''" title="" @click.prevent="changeOrder(11)"></i>
                            <i class="el-icon-caret-bottom" :class="OrderCheckActive==12?'on':''" title="" @click.prevent="changeOrder(12)"></i>
                        </span>
                    </div>
                    <div class="item">
                        <span class="name">专业级别</span>
                        <span class="icon">
                            <i class="el-icon-caret-top" :class="OrderCheckActive==21?'on':''" title="" @click.prevent="changeOrder(21)"></i>
                            <i class="el-icon-caret-bottom" :class="OrderCheckActive==22?'on':''" title="" @click.prevent="changeOrder(22)"></i>
                        </span>
                    </div>
                    <div class="item">
                        <span class="name">专业特色</span>
                        <span class="icon">
                            <i class="el-icon-caret-top" :class="OrderCheckActive==31?'on':''" title="" @click.prevent="changeOrder(31)"></i>
                            <i class="el-icon-caret-bottom" :class="OrderCheckActive==32?'on':''" title="" @click.prevent="changeOrder(32)"></i>
                        </span>
                    </div>

                    <div class="clear"></div>
                </div>
            </div>
            <div class="majortable">
                <div class="mhead">
                    <div class="td">专业名称</div>
                    <div class="td">专业排名</div>
                    <div class="td">专业级别</div>
                    <div class="td">专业特色</div>
                    <div class="clear"></div>
                </div>
                <div class="table">

                    <div class="tr" v-for="(item, index) in mainData.Data" :key="index">
                        <div class="td"><span class="major-name" @click="openMajorDetail(item)">{{item.MajorName}}</span></div>
                        <div class="td">{{item.MajorRankNum}}</div>
                        <div class="td">{{item.MajorLevel}}</div>
                        <div class="td">{{item.MajorTagNames}}</div>
                        <div class="clear"></div>
                    </div>

                    <!-- <div class="tr">
                        <div class="td">乌尔都语</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">国家级一流专业建设点（双万计划） 、国家特色专业建设点</div>
                        <div class="clear"></div>
                    </div>
                    <div class="tr">
                        <div class="td">阿拉伯语</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">国家级一流专业建设点（双万计划） 、国家特色专业建设点</div>
                        <div class="clear"></div>
                    </div>
                    <div class="tr">
                        <div class="td">宗教学</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">--</div>
                        <div class="clear"></div>
                    </div>
                    <div class="tr">
                        <div class="td">乌尔都语</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">国家级一流专业建设点（双万计划） 、国家特色专业建设点</div>
                        <div class="clear"></div>
                    </div>
                    <div class="tr">
                        <div class="td">阿拉伯语</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">国家级一流专业建设点（双万计划） 、国家特色专业建设点</div>
                        <div class="clear"></div>
                    </div>
                    <div class="tr">
                        <div class="td">宗教学</div>
                        <div class="td">1</div>
                        <div class="td">A+</div>
                        <div class="td">--</div>
                        <div class="clear"></div>
                    </div> -->
                </div>
            </div>
            <div v-show="pageStatus">
                <div v-if="pageText!='没有更多数据了'" class="more" @click="getMore">{{pageText}}</div>
                <div v-else class="more-n">{{pageText}}</div>
            </div>

        </div>
        <div class="search_layer_back" id="search_layer_back" @click.prevent="CloseMajorSearch()">
            <div class="search_layer_box" @click.prevent.stop="">
                <div class="m_list" id="search_list_box">
                    <div class="s_box">
                        <div class="tit">专业特色：</div>
                        <div class="box">
                            <span class="item" :class="(MajorSearchCheckData.length==0?'on':'')" @click.prevent="MajorSearchCheck('')">不限</span>
                            <span class="item" :class="(MajorSearchCheckData.find(f=>f.Id==item.Id)?'on':'')" v-for="item in MajorSearchData" :key="item.Id" @click.prevent="MajorSearchCheck(item)">{{item.Name}}</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="btnbox">
                        <div class="s_back" @click.prevent="CloseMajorSearch()"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                        <div class="s_submit" @click.prevent="SeachFormSubmit()"><i class="el-icon-search"></i> 查 询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
import { cloneDeep } from "lodash";
export default {
    data() {
        return{
            UserData:null,
            HaveVip:false,
            MajorName:'',
            OrderCheckActive:null,
            MajorSearchData:[],
            MajorSearchCheckData:[],
            MajorSearchCheckDataCopy:[],

            mainData: {
                Data: []
            },

            completeMatch: false,

            query: {
                CollegeId: 0,
                MajorName: "",
                IsMajorFullMatch: false,
                MajorTagIdList: [
                    
                ],
                OrderType: 0,
                isOrderDesc: false
            },
            pageIndex: 1,
            pageSize: 10,

            pageText: '点击加载更多...',
            pageStatus: false,

        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        this.query.CollegeId = this.$route.query.id;

                        API.Query.GetTagList({TagType: 3}).then(res => {
                            this.MajorSearchData = res.Data
                        })

                        this.getData()

                        // this.provinceObj = JSON.parse(temp);
                        // this.AMDParameters.ProvinceId = this.provinceObj.ProvinceId;
                        // this.AMDParameters.CollegeId = this.$route.query.id;
                        // this.GetAdmissionCollege(this.provinceObj.ProvinceId);// 获取院校录取数据
                        // this.GetAdmissionMajorQueryParameters(this.provinceObj.ProvinceId);// 获取专业录取数据查询参数
                    }
                }
            }
        }
        // this.MajorSearchData=[
        //     {Id:1,Name:'国家级一流专业建设点（双万计划）'},
        //     {Id:2,Name:'省级一流专业建设点（双万计划）'},
        //     {Id:3,Name:'国家特色专业建设点'},
        //     {Id:4,Name:'卓越工程师'},
        //     {Id:5,Name:'双高计划(高水平学校A档)'},
        //     {Id:6,Name:'双高计划(高水平学校B档)'},
        //     {Id:7,Name:'双高计划(高水平学校C档)'},
        //     {Id:8,Name:'双高计划(高水平专业群A档)'},
        //     {Id:9,Name:'双高计划(高水平专业群B档)'},
        //     {Id:10,Name:'双高计划(高水平专业群C档)'}
        // ]
    },
    methods:{
        openMajorDetail(it) {
            let url = this.$router.resolve({
                path: '/query/majordetail',
                query: {
                    id: it.MajorId
                }
            })
            window.open(url.href, '_blank')
        },
        sureSearch() {
            this.query.MajorName = this.MajorName
            this.pageIndex = 1
            this.mainData = {
                Data: []
            }
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.6)'
            })

            this.getData().then(() => {
                loading.close()
            })
        },
        selectMatch() {
            this.completeMatch = !this.completeMatch

            this.query.IsMajorFullMatch = this.completeMatch
        },
        changeOrder(val) {
            if (this.OrderCheckActive===val) return
            this.OrderCheckActive=val


            if (val === 11) {
                this.query.OrderType = 1
                this.query.isOrderDesc = false
            }
            if (val === 12) {
                this.query.OrderType = 1
                this.query.isOrderDesc = true
            }
            if (val === 21) {
                this.query.OrderType = 2
                this.query.isOrderDesc = false
            }
            if (val === 22) {
                this.query.OrderType = 2
                this.query.isOrderDesc = true
            }
            if (val === 31) {
                this.query.OrderType = 3
                this.query.isOrderDesc = false
            }
            if (val === 32) {
                this.query.OrderType = 3
                this.query.isOrderDesc = true
            }

            this.pageIndex = 1
            this.mainData = {
                Data: []
            }
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.6)'
            })

            this.getData().then(() => {
                loading.close()
            })

        },
        async getData() {
            this.pageStatus = false
            let res = await API.Query.GetCollegeMajorStrengthList(this.pageIndex, this.pageSize,this.query)

            let list = this.mainData.Data.concat(res.Data.Data)
            res.Data.Data = list
            this.mainData = res.Data


            if (this.pageIndex>=this.mainData.TotalPage) {
                this.pageText = '没有更多数据了'
            } else this.pageText = '点击加载更多...'

            this.pageStatus = true
            return
        },
        getMore() {
            if (this.pageIndex < this.mainData.TotalPage) {
                this.pageIndex++

                let loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0,0,0,0.6)'
                })

                this.getData().then(() => {
                    loading.close()
                })
            }
            
        },
        // 排序选中
        OrderCheck(val){
            this.OrderCheckActive=val;
        },
        // 关闭筛选
        CloseMajorSearch(){
            document.getElementById('search_layer_back').classList.add("layerbackhide");
            document.getElementById('search_layer_back').classList.remove("layerbackshow");
            let obj = document.getElementById("search_list_box");
            obj.classList.remove("layerboxshow");
            obj.classList.add("layerboxhide");
            let st = setTimeout(()=>{
                document.getElementById('search_layer_back').style.display='none';
                obj.style.display='none';
                clearTimeout(st);
            },300);
        },
        // 打开筛选
        OpenMajorSearch(){
            this.MajorSearchCheckData = cloneDeep(this.MajorSearchCheckDataCopy);
            document.getElementById('search_layer_back').style.display='block';
            document.getElementById('search_layer_back').classList.add("layerbackshow");
            document.getElementById('search_layer_back').classList.remove("layerbackhide");
            let obj = document.getElementById("search_list_box");
            obj.style.display='block';
            obj.classList.remove("layerboxhide");
            obj.classList.add("layerboxshow");
        },
        // 筛选选中
        MajorSearchCheck(item){
            if(item!=''){
                let flag = this.MajorSearchCheckData.find(f=>f.Id==item.Id);
                if(flag){
                    this.MajorSearchCheckData = this.MajorSearchCheckData.filter(f=>f.Id!==item.Id);
                }
                else{
                    this.MajorSearchCheckData.push(item);
                }
            }
            else{
                this.MajorSearchCheckData = [];
            }
        },
        // 移除筛选
        MajorSearchRemove(item){
            if(item!=''){
                this.MajorSearchCheckDataCopy = this.MajorSearchCheckDataCopy.filter(f=>f.Id!==item.Id);
            }
            else{
                this.MajorSearchCheckDataCopy = [];
            }

            if (this.MajorSearchCheckDataCopy.length===0) {
                this.query.MajorTagIdList = []
            } else {
                this.query.MajorTagIdList = this.MajorSearchCheckDataCopy.map(it => {
                    return it.Id
                })
            }

            this.pageIndex = 1
            this.mainData = {
                Data: []
            }
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.6)'
            })

            this.getData().then(() => {
                loading.close()
            })

        },
        // 筛选确定
        SeachFormSubmit(){
            this.MajorSearchCheckDataCopy = cloneDeep(this.MajorSearchCheckData);

            if (this.MajorSearchCheckDataCopy.length===0) {
                this.query.MajorTagIdList = []
            } else {
                this.query.MajorTagIdList = this.MajorSearchCheckDataCopy.map(it => {
                    return it.Id
                })
            }

            this.pageIndex = 1
            this.mainData = {
                Data: []
            }
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.6)'
            })

            this.getData().then(() => {
                loading.close()
            })

            this.CloseMajorSearch();
        }
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .con_box{
        margin: 0 20px;
        padding-top: 20px;
        .searchbox{
            height: 46px;
            .sxbox{
                width: 150px;
                height: 44px;
                color: #666;
                text-align: center;
                line-height: 44px;
                border-radius: 3px;
                float: left;
                border: 1px solid #ddd;
                i{
                    color: rgba(var(--themecolor),0.8);
                    margin-left: 5px;
                }
            }
            .sxbox:hover{
                cursor: pointer;
                border: 1px solid rgba(var(--themecolor),0.4);
            }
            .match-box {
                height: 44px;
                line-height: 3;
                padding: 0 10px;
                margin-left: 20px;
                float: left;
                cursor: pointer;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border: 1px solid #ddd;
                border-right:none;
                display: flex;
                align-items: center;
                .check-box {
                    margin-right: 5px;
                    font-size: 12px;
                    color: #666;
                    cursor: pointer;
                }
                input[type="checkbox"]:checkbox::after {
                    background-color: #fff;
                }
                .text {
                    font-size: 13px;
                    color: #666;
                }
            }
            .input{
                width: calc(100% - 265px);
                height: 46px;
                float: left;
                input{
                    float: left;
                    width: calc(100% - 202px);
                    height: calc(100% - 2px);
                    text-indent: 20px;
                    color: #666;
                    font-size: 16px;
                    line-height: 1;
                    line-height: 44px;
                    border: 1px solid #e0e0e0;
                    outline: none;
                }
                .btn{
                    float: left;
                    width: 200px;
                    height: 46px;
                    background-color: rgba(var(--themecolor),0.8);
                    color: #fff;
                    font-size: 16px;
                    text-align: center;
                    // line-height: 46px;
                    border: none;
                    border-radius: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;

                    &:focus {
                        border: none;
                    }
                    i{
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
                .btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .searchlist{
            margin-top: 20px;
            .ttxt{
                width: 90px;
                float: left;
                color: #666;
                font-size: 15px;
                line-height: 38px;
            }
            .t_data{
                width: calc(100% - 90px);
                float: right;
                .data{
                    border: 1px solid rgba(var(--themecolor),0.75);
                    border-radius: 3px;
                    position: relative;
                    overflow: hidden;
                    margin: 0px 10px 0px 0px;
                    padding: 2px 41px 2px 5px;
                    .d{
                        display: inline-block;
                        color: #666;
                        font-size: 12px;
                        border: 1px solid #ddd;
                        border-radius: 20px;
                        background-color: #fff;
                        height: 20px;
                        line-height: 20px;
                        padding: 0px 5px 0px 12px;
                        margin: 4px 4px;
                        i{
                            margin-left: 6px;
                            color: #999;
                            font-size: 14px;
                        }
                        i:hover{
                            color: rgba(var(--themecolor),1);
                            cursor: pointer;
                        }
                    }
                    .close{
                        width: 36px;
                        text-align: center;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top:0;
                        background-color: rgba(var(--themecolor),0.75);
                        i{
                            display: block;
                            color: #f0ecec;
                            font-size:18px;
                            height: 20px;
                            line-height: 20px;
                            width: 100%;
                            position: relative;
                            top: calc(50% - 10px);
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                        }
                    }
                    .close:hover{
                        cursor: pointer;
                    }
                    .close:hover i{
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }
            }
        }
        .orderbox{
            margin-top: 10px;
            .oitem{
                padding: 3px 10px;
                color: #666;
                font-size: 14px;
                border: 1px solid #ddd;
                margin-right: 10px;
                float: left;
                i{
                    color: #999;
                    margin-left: 3px;
                }
            }
            .oitem:hover{
                cursor: pointer;
                opacity: 0.8;
            }
            .oitem.on{
                color: #fff;
                background-color: rgba(var(--themecolor),0.75);
                border: 1px solid rgba(var(--themecolor),0.75);
                i{
                    color: #fff;
                }
            }
        }
        .order {
            display: flex;
            .item {
                display: flex;
                align-items: center;
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px dashed #999;
                .name {
                    margin: 0 5px;
                    color: #666;
                }
                .icon {
                    i {
                        display: block;
                        color: #888;
                        font-size: 20px;
                        cursor: pointer;
                    }

                    .on {
                        color: rgba(var(--themecolor), 0.8);
                    }
                }
            }
        }
        .majortable{
            border: 1px solid #ddd;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 20px;
            .mhead{
                height: 60px;
                line-height: 60px;
                .td{
                    color: #666;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    float: left;
                }
                .td:nth-child(1),.td:nth-child(4){
                    width: calc((100% - 400px) / 2);
                    text-indent: 40px;
                }
                .td:nth-child(2),.td:nth-child(3){
                    width: 200px;
                    text-align: center;
                }
            }
            .table{
                .tr{
                    width: 100%;
                    .td{
                        color: #666;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        vertical-align: middle;
                        float: left;
                        padding: 20px 0;
                    }
                    .td:nth-child(1),.td:nth-child(4){
                        width: calc(((100% - 400px) / 2) - 60px);
                        padding: 0 20px 0 40px;
                    }
                    .td:nth-child(2),.td:nth-child(3){
                        width: 200px;
                        text-align: center;
                        text-indent: 22px;
                    }
                    .major-name {
                        &:hover {
                            cursor: pointer;
                            color: rgba(var(--themecolor), 0.8);
                        }
                    }
                }
                .tr:nth-child(2n+1){
                    background-color: rgba(var(--themecolor),0.03);
                }
            }
        }
        .more {
            padding: 10px;
            margin: 20px;
            text-align: center;
            cursor: pointer;
            color: #555;
            &:hover {
                color: rgba(var(--themecolor), 0.8);
            }
        }
        .more-n {
            padding: 10px;
            margin: 20px;
            text-align: center;
            color: #999;
        }

    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
    .search_layer_back{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 19;
        width: 100%;
        height: 100%;
        display: none;
        opacity: 0;
        background-color: rgba(0,0,0,0.5);
        user-select: none;
        .search_layer_box{
            margin: 50px auto 0;
            max-width: 1420px;
            height: calc(100% - 100px);
            position: relative;
        }
        .m_list{
            border: 1px solid #ddd;
            margin: 0 20px;
            background-color: rgba(255,255,255,9);
            box-shadow: 0px 3px 5px 0px #d4d2d2;
            border-radius: 5px;
            position: absolute;
            z-index: 2;
            opacity: 0;
            display: none;
            height: 100%;
            .sx_scrollbar{
                width: 100%;
                height: 100%;
            }
            .s_box{
                margin: 0 20px 15px 20px;
                // border-bottom: 1px dashed #c4c2c2;
                padding-bottom: 5px;
                .tit{
                    color: #666;
                    font-size: 14px;
                    float: left;
                    width: 80px;
                    text-align: right;
                }
                .box{
                    width: calc(100% - 100px);
                    float: right;
                    .item{
                        display: inline-block;
                        color: #666;
                        font-size: 12px;
                        padding: 2px 18px;
                        border: 1px solid #e2e2e2;
                        border-radius: 20px;
                        margin: 0px 8px 10px;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                        position: relative;
                        i{
                            margin-left: 5px;
                            color: #999;
                        }
                    }
                    .item:hover{
                        border: 1px solid rgba(var(--themecolor),0.75);
                        cursor: pointer;
                        color: rgba(var(--themecolor),0.75);
                    }
                    .item.on{
                        border: 1px solid rgba(var(--themecolor),0.75);
                        background-color: rgba(var(--themecolor),0.75);
                        color: #fff;
                    }
                }
            }
            .s_box:nth-child(1){
                margin-top: 20px;
            }
            .level {
                
                .level-title {
                    padding-left: 60px;
                    color: #666;
                    font-size: 14px;
                    width: 80px;
                    text-align: right;
                }
                .level-box {
                    .level-item {
                        color: #666;
                        font-size: 12px;
                        padding: 2px 18px;
                        border: 1px solid #e2e2e2;
                        border-radius: 20px;
                        margin: 0px 8px 10px;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                        position: relative;
                        &:hover {
                            border: 1px solid rgba(var(--themecolor),0.75);
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.75);
                        }
                    }
                    .level-item.on {
                        border: 1px solid rgba(var(--themecolor),0.75);
                        background-color: rgba(var(--themecolor),0.75);
                        color: #fff;
                    }
                }
            }
            .btnbox{
                width: 100%;
                height: 60px;
                background-color: #fff;
                border-top: 1px solid #ddd;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
                text-align: center;
                .s_back{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 3px;
                    background-color: #e0e0e0;
                    color: #666;
                    font-size: 15px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin: 10px 20px;
                    display: inline-block;
                }
                .s_back:hover{
                    cursor: pointer;
                    background-color: #d6d6d6;
                }
                .s_submit{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 3px;
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                    font-size: 15px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin: 10px 20px;
                    display: inline-block;
                }
                .s_submit:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .m_list.layerboxshow {
            animation: layerboxshow 0.3s ease 0s 1;
            -webkit-animation: layerboxshow 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerboxshow {
                from {
                    top: -100px;
                    opacity: 0;
                }
                to {
                    top: 0px;
                    opacity: 1;
                }
            }
            @keyframes layerboxshow {
                from {
                    top: -100px;
                    opacity: 0;
                }
                to {
                    top: 0px;
                    opacity: 1;
                }
            }
        }
        .m_list.layerboxhide {
            animation: layerboxhide 0.3s ease 0s 1;
            -webkit-animation: layerboxhide 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerboxhide {
                from {
                    top: 0px;
                    opacity: 1;
                }
                to {
                    top: -100px;
                    opacity: 0;
                }
            }
            @keyframes layerboxhide {
                from {
                    top: 0px;
                    opacity: 1;
                }
                to {
                    top: -100px;
                    opacity: 0;
                }
            }
        }
    }
    .search_layer_back.layerbackshow {
        animation: layerbackshow 0.3s ease 0s 1;
        -webkit-animation: layerbackshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes layerbackshow {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @keyframes layerbackshow {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    .search_layer_back.layerbackhide {
        animation: layerbackhide 0.3s ease 0s 1;
        -webkit-animation: layerbackhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes layerbackhide {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
        @keyframes layerbackhide {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
}
</style>