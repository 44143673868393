<template>
    <div class="tab_con">
        <div class="con_box">
            <div class="content">
                <div class="conbox" @click.prevent="ToArticleDetail(item.Id)" v-for="(item, index) in CollegeConstitutionData" :key="index">
                    <div class="tbox">{{item.Title}}</div>
                    <div class="btns">
                        <div class="num">年份：{{item.Year||'-'}}</div>
                        <!-- <div class="time">发布时间：{{cons.CreateTime.substring(0,10)}}</div> -->
                        <div class="b"><span>发布时间：{{item.ReleaseTime?item.ReleaseTime.substring(0,10):'-'}}</span></div>
                    </div>
                    <div class="clear"></div>
                </div>
                <!-- <div class="conbox">
                    <div class="tbox">北京大学密西根学院2023届毕业生转专业政策</div>
                    <div class="btns">
                        <div class="b"><span>发布时间：2024-6-10</span></div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="conbox">
                    <div class="tbox">北京大学2022-2023学年本科教学转专业政策</div>
                    <div class="btns">
                        <div class="b"><span>发布时间：2024-6-10</span></div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="conbox">
                    <div class="tbox">北京大学本科教学转专业政策</div>
                    <div class="btns">
                        <div class="b"><span>发布时间：2024-6-10</span></div>
                    </div>
                    <div class="clear"></div>
                </div> -->
            </div>
            <div class="pages" @click.prevent="pagesbtn()">
                <i class="iconfont" v-if="CollegeConstitutionData.length==0&&pageIndex==1">&#xe67c;</i>
                <span>{{pagetxt}}</span>
            </div>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default {
    data() {
        return{
            CollegeSetActive:1,
            UserData:null,
            HaveVip:false,

            CollegeConstitutionData: [],

            pagetxt: '未查询到数据',
            pageIndex: 1,
            pageSize:10,

            UserData: {}

        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {

                        this.GetCollegeConstitutionList()
                        // this.provinceObj = JSON.parse(temp);
                        // this.AMDParameters.ProvinceId = this.provinceObj.ProvinceId;
                        // this.AMDParameters.CollegeId = this.$route.query.id;
                        // this.GetAdmissionCollege(this.provinceObj.ProvinceId);// 获取院校录取数据
                        // this.GetAdmissionMajorQueryParameters(this.provinceObj.ProvinceId);// 获取专业录取数据查询参数
                    }
                }
            }
        }
    },
    methods:{
        // 学科类别选中
        CollegeSetCheck(val){
            this.CollegeSetActive=val;
        },
        // 详情
        ToArticleDetail(id){
            window.open(this.$router.resolve({ path: "/query/articledetail?id="+id }).href, "_blank");
        },

        // 获取院校政策列表
        GetCollegeConstitutionList(){
            API.Query.GetCollegeNewsList(this.pageIndex,this.pageSize,{CollegeId:this.$route.query.id, CategoryCode: 'ZZYZC'}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.CollegeConstitutionData.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到数据！';
                }
            });
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetCollegeConstitutionList();
                }
            }else{
                if(this.CollegeConstitutionData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .con_box{
        margin: 0 20px;
        padding-top: 20px;
        .content{
            border: 1px solid #ddd;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            // min-height: 400px;
            .conbox{
                margin: 20px;
                border: 1px solid #efefef;
                border-radius: 5px;
                padding: 15px 0 15px 15px;
                cursor: pointer;

                .tbox{
                    color: #666;
                    font-size: 14px;
                    float: left;
                    width: calc(100% - 400px);
                }
                .btns{
                    width: 400px;
                    float: left;

                    text-align: right;

                    .num {
                        width: 150px;
                        color: #666;
                        font-size: 14px;

                        float: left;
                    }

                    .b{
                        display: inline-block;
                        width: 200px;
                        margin-right: 20px;
                        text-align: center;
                        span{
                            // text-decoration: underline;
                            color: #666;
                            font-size: 14px;
                        }
                        // span:hover{
                        //     cursor: pointer;
                        //     color: rgba(var(--themecolor),1);
                        // }
                    }
                }
            }
            .conbox:hover{
                background-color: rgba(var(--themecolor), 0.06);
            }
        }
        .pages{
            width: 100%;
            min-height: 50px;
            line-height: 50px;
            color: #666;
            font-size: 16px;
            text-align: center;
            letter-spacing: 1px;
            margin-bottom: 10px;
            user-select: none;
            .iconfont{
                font-size: 50px;
                display: block;
                margin-top: 40px;
                color: #9b9b9b;
            }
        }
        .pages:hover{
            color: rgb(var(--themecolor));
            cursor: pointer;
        }
    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>